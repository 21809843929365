exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-astrology-astro-nav-tsx": () => import("./../../../src/pages/astrology/[astroNav].tsx" /* webpackChunkName: "component---src-pages-astrology-astro-nav-tsx" */),
  "component---src-pages-astrology-index-tsx": () => import("./../../../src/pages/astrology/index.tsx" /* webpackChunkName: "component---src-pages-astrology-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-mystars-index-tsx": () => import("./../../../src/pages/mystars/index.tsx" /* webpackChunkName: "component---src-pages-mystars-index-tsx" */),
  "component---src-pages-mystars-my-stars-nav-tsx": () => import("./../../../src/pages/mystars/[myStarsNav].tsx" /* webpackChunkName: "component---src-pages-mystars-my-stars-nav-tsx" */),
  "component---src-pages-numerology-index-tsx": () => import("./../../../src/pages/numerology/index.tsx" /* webpackChunkName: "component---src-pages-numerology-index-tsx" */),
  "component---src-pages-numerology-numerlogy-nav-tsx": () => import("./../../../src/pages/numerology/[numerlogyNav].tsx" /* webpackChunkName: "component---src-pages-numerology-numerlogy-nav-tsx" */),
  "component---src-pages-one-last-step-index-tsx": () => import("./../../../src/pages/one-last-step/index.tsx" /* webpackChunkName: "component---src-pages-one-last-step-index-tsx" */),
  "component---src-pages-payment-failed-tsx": () => import("./../../../src/pages/payment-failed.tsx" /* webpackChunkName: "component---src-pages-payment-failed-tsx" */),
  "component---src-pages-plans-index-tsx": () => import("./../../../src/pages/plans/index.tsx" /* webpackChunkName: "component---src-pages-plans-index-tsx" */),
  "component---src-pages-plans-plan-name-tsx": () => import("./../../../src/pages/plans/[planName].tsx" /* webpackChunkName: "component---src-pages-plans-plan-name-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-tarot-index-tsx": () => import("./../../../src/pages/tarot/index.tsx" /* webpackChunkName: "component---src-pages-tarot-index-tsx" */),
  "component---src-pages-tarot-tarot-nav-tsx": () => import("./../../../src/pages/tarot/[tarotNav].tsx" /* webpackChunkName: "component---src-pages-tarot-tarot-nav-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

